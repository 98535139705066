$(window).load(function () {
  var jqWindow = $(window);
  var jqBody = $('body');
  var titleBox = $('#titleBox');
  var titleArea = $('#titleArea');
  var gps = $('#gps');
  var gpsArea = $('#gpsArea');
  var toolbar = $('#toolbar');
  var toolbarArea = $('#toolbarArea');
  var sidebar = $('.sidebarGroup');
  var sidebarArea = $('.sidebarArea');
  var warnings = $('#warnings');
  var drawerArea = $('#drawerArea');
  var drawerContainer = $('#drawerContainer');
  var gpsWasSticky = null;
  var drawerNaturalTop;
  var toolbarNaturalTop;
  var toolbarHeight;
  var formerLastEl = null;
  var width;
  var positions = [];
  var positionEls = {};
  var gpsHeight;
  var gpsNaturalTop;
  var warningsHeight;
  var bodyTopHeight;

  //reset all heights
  var clearHeights = function() {
    toolbar.removeData('naturalTop');
    toolbar.removeData('sticky');
    gps.removeData('naturalTop');
    toolbarNaturalTop = null;
    toolbarHeight = null;
    drawerNaturalTop = null;
    bodyTopHeight = null;
    formerLastEl = null;
    width = null;
    titleBox.removeAttr('style');
    titleArea.removeAttr('style');
    gpsArea.removeAttr('style');
    sidebar.removeAttr('style');
    drawerArea.removeAttr('style');
    titleBox.empty();
    titleArea.removeAttr('style');
    toolbar.removeAttr('style');
    gps.removeAttr('style');
    gpsArea.removeAttr('style');
    //$('#sidebar,#gps').css({'position':'relative'});
    jqWindow = $(window);
    titleBox.trigger('guidChange');
  };
  //get where the toolbars should be before we muck with stuff
  var setHeights = function () {
    if(bodyTopHeight !== jqBody.position().top) {
      clearHeights();
      titleBox.prop('resetOrder',1);
      bodyTopHeight = jqBody.position().top;
    }

    if (toolbar.length && toolbar.is(":visible")) {
      toolbarNaturalTop = toolbarArea.offset().top;
      toolbar.data('naturalTop', toolbarNaturalTop);
      toolbarHeight = toolbar.outerHeight();
    } else if (sidebar.length) {
      toolbarNaturalTop = sidebarArea.offset().top;
    }
    if (drawerArea.length > 0) {
      drawerNaturalTop = drawerContainer.offset().top;
    }

    //grab the height of the warnings bars for offsetting all static elements
    if (warnings.length) {
      warningsHeight = warnings.outerHeight();
    }
    if (gps.length) {
      gpsHeight = gps.outerHeight();
      gpsArea.height(gpsHeight);
      gpsNaturalTop = gpsArea.offset().top;
      gps.data('naturalTop', gpsNaturalTop);
      gps.trigger('gps.heightChange',[]);
    }
  };
  var scrollSticky = function (scrollTop) {

    var menubar = document.getElementById("menubar");
    if(!menubar) {
      return;
    }

    var menubarHeight = menubar.getBoundingClientRect().height;
    if (toolbarNaturalTop === null || jqBody.position().top !== bodyTopHeight) {
      setHeights();
    }

    //if we have scrolled past the toolbar put it on top
    var topOffset = warningsHeight + bodyTopHeight + menubarHeight;

    if (scrollTop + topOffset > toolbarNaturalTop) {
      sidebar.css({'position': 'fixed', 'top': topOffset});
      if (toolbar.data('sticky')) {
        toolbar.css({position: 'fixed', 'top': topOffset});
        gpsWasSticky = null;
      }
      sidebar.addClass('sticky');
    } else {
      sidebar.css({'position': 'relative', top: 0});
      toolbar.css({'position': 'relative', top: 0});
      sidebar.removeClass('sticky');
    }

    if (scrollTop + topOffset > drawerNaturalTop) {
      drawerArea.addClass('scrolled');
      jqWindow.trigger('reset');
    } else {
      drawerArea.removeClass('scrolled');
      jqWindow.trigger('reset');
    }

    var heightChange;
    if (!toolbar.data('sticky')) {
      heightChange = false;
      bodyTopHeight = bodyTopHeight || jqBody.position().top || 0;
      if (scrollTop + topOffset > gpsNaturalTop) {
        if (!gps.hasClass("stickyGps")) {
          gps.addClass("stickyGps");
          heightChange = true;
        }
        if (gps.css("position") !== 'fixed' || gps.position().top !== topOffset) {
          gps.css({'position': 'fixed', 'top': topOffset});
          heightChange = true;
        }
        gpsWasSticky = null;
      } else {
        if (gps.hasClass("stickyGps")) {
          gps.removeClass("stickyGps");
          heightChange = true;
        }
        if (gps.css("position") !== 'relative' || gps.position().top !== 0) {
          gps.css({'position': 'relative', 'top': 0});
          heightChange = true;
        }
        gpsWasSticky = null;
      }
      if (heightChange) {
        gps.trigger('gps.heightChange', []);
      }
    } else if((scrollTop + topOffset <= gpsNaturalTop) && (gps.hasClass("stickyGps"))) {
        gps.removeClass("stickyGps");
        heightChange = true;
    }

    var barHeight = gpsHeight;
    if (gps.length && gpsWasSticky !== gps.hasClass("stickyGps")) {
      heightChange = false;
      gpsWasSticky = gps.hasClass("stickyGps");
      if (gpsHeight !== gps.outerHeight()) {
        gpsHeight = gps.outerHeight();
        heightChange = true;
      }
      if (barHeight !== gpsHeight) {
        barHeight = gpsHeight;
        heightChange = true;
      }
      if (heightChange) {
        gps.trigger('gps.heightChange');
      }
    }

    if (titleBox.prop('disableSticky')) {
      gps.trigger('gps.heightChange',[]);
      return;
    }


    if (toolbar.data('sticky')) {
      gps.trigger('gps.heightChange',[]);
      barHeight = toolbarHeight;
    }
    if (width !== jqWindow.width() || titleBox.prop('resetOrder')) {
      titleBox.removeProp('resetOrder');
      width = jqWindow.width();
      positions = [];
      positionEls = {};
      //var titles = $('.barTitle').not('[id="queryDescription"]').not('.tocTitle,.chapterrefblockTitle');
      var titles = $('#childContent .barTitle[id!="queryDescription"]:not(.chapterrefblockTitle)');
      titles.each(function () {
        var titleEl = $(this);
        if (titleEl.parent().is('#titleBox')) {
          return;
        }
        if (titleEl.css('display') !== 'none') {
          var top = Math.floor(titleEl.offset().top);
          positions.push(top);
          positionEls[top] = titleEl;
        }
      });
      positions.sort(function (a, b) { return a - b; });
    }

    var lastEl = null;
    for (var i = 0, len = positions.length; i < len; i++) {
      var top = positions[i];
      var titleEl = positionEls[top];
      if (top < scrollTop + topOffset + barHeight + titleEl.outerHeight() + 8) {
        lastEl = positionEls[top];
      } else {
        break;
      }
    }
    if (lastEl && !(positionEls[Math.floor(lastEl.offset().top)] && lastEl.is(positionEls[Math.floor(lastEl.offset().top)]))) {
      width = -1;
      $(window).trigger('scroll.sticky');
      return;
    }


    if (lastEl && formerLastEl !== lastEl) {
      if (!formerLastEl) {
        titleArea.css({'display': 'block'});
        titleArea.css({'top': barHeight + topOffset});
      }
      titleBox.empty();
      var titleClone = lastEl.clone().attr('id', function (i, val) { return 'titleBox_' + val; }).appendTo(titleBox);
      titleClone.find('.selectionBoxInput').attr('id', 'titleBoxSelectionInput');
      titleClone.find('.selectionLabel').attr('id', 'titleBoxSelectionLabel').attr('for', 'titleBoxSelectionInput');
      titleClone.find('.footnoteref').removeAttr('id');
      titleBox.trigger('guidChange');
    } else if (!lastEl) {
      titleBox.trigger('guidChange');
      titleArea.css({'display': 'none'});
    }

    formerLastEl = lastEl;


  };

  var lastKnownScrollPosition = 0;
  var ticking = false;
  var scrollHandler = function() {
    lastKnownScrollPosition = window.scrollY;

    if(!ticking) {
      window.requestAnimationFrame(function() {
        scrollSticky(lastKnownScrollPosition);
        ticking = false;
      });
      ticking = true;
    }
  };
  $('#topArea').on('webkitAnimationEnd onanimationend msAnimationEnd animationend', scrollHandler);
  // $(window).on('resize', clearHeights);
  // $(window).on('scroll.sticky resize', scrollHandler);
  var passiveIfSupported = false;
  try {
    window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function() { passiveIfSupported = { passive: true }; } }));
  } catch(err) {}
  window.addEventListener("scroll", scrollHandler, passiveIfSupported);
  window.addEventListener("resize", clearHeights, passiveIfSupported);
  window.addEventListener("resize", scrollHandler, passiveIfSupported);
  scrollHandler();
});
